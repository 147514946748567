import * as React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CareContinuumIcons from "../../images/Hiv-care-continuum-icons.svg";
import CareContinuumIconsMobile from "../../images/Hiv-care-continuum-icons-mobile.svg";
import BackToTop from "../global/BackToTop.js";
import References from "../global/references.js";

class Content extends Component {
  render() {
    return (
      <div className={`${this.props.show ? "d-block" : "d-none"}`}>
        <Container fluid className="hero care-continuum" />
        <Container className="care-continuum">
          <Row>
            <Col xs={10} className="m-auto px-0">
              <div className="hero-callout muted-yellow-dark">
                
                <h1 className="title ">
                  <span className="sub-title d-block">HIV CARE CONTINUUM</span>
                  FROM HIV PREVENTION TO VIRAL SUPPRESSION
                </h1>
              </div>
              <p>
                Today’s HIV treatments have been shown to be effective at
                suppressing the virus if taken as prescribed by a healthcare
                provider. Viral suppression, which means the amount of virus in
                the blood is less than 200 copies/mL or is so low it cannot be
                measured by a test, is the ultimate goal of HIV care and
                treatment.<sup>1</sup> While there is no cure for HIV,
                maintaining viral suppression can help people with HIV live
                longer, healthier lives and can help reduce the chance of
                passing HIV to others through sex.<sup>1-3</sup>
              </p>
              <p>
                The HIV care continuum is a public health model that maps the
                steps that individuals should go through from HIV diagnosis to
                viral suppression. This can help healthcare providers make sure
                people living with HIV are getting the care they need.
                <sup>4</sup> In addition, the Centers for Disease Control and
                Prevention (CDC) tracks each step in the HIV care continuum to
                provide us with a population-level view of the progress the
                United States is making in addressing the HIV epidemic.
                <sup>5</sup>
              </p>
              <h2 className="title pt-4">Steps in the HIV Care Continuum</h2>
              <p>
                The HIV care continuum begins with a new HIV diagnosis and sets
                forth the steps people should take to get care and treatment
                with the objective of helping everyone achieve viral
                suppression. These steps include and are defined by the CDC as
                follows:
              </p>
              <ul className="indented-list">
                <li>
                  <strong>Diagnosed. </strong>
                  Receiving a positive HIV test confirmed by a healthcare
                  provider. On a population level, statistics about new HIV
                  diagnoses measure the percentage of the total number of people
                  living with HIV who are aware of their status<sup>1</sup>
                </li>
                <li>
                  <strong>Linked to care. </strong>
                  Having at least one documented CD4 or viral load test within
                  30 days of new HIV diagnosis. The goal is to get everyone who
                  tests positive for HIV linked to care within one month of
                  their diagnosis<sup>1</sup>
                </li>
                <li>
                  <strong>Received care. </strong>
                  Having had at least one CD4 or viral load test run by a
                  healthcare professional.<sup>1</sup> It tracks the percentage
                  of people who have been diagnosed and received care in a given
                  year. Once people get into care with a healthcare provider,
                  they can start treatment<sup>4</sup>
                </li>
                <li>
                  <strong>Retained in care. </strong>
                  Having had two or more CD4 or viral load tests, performed at
                  least three months apart.<sup>1</sup> It is important for
                  people living with HIV to have ongoing care because they need
                  to be on treatment regimens for their entire lives, and need
                  the help of a healthcare provider to prescribe the medication,
                  monitor the dosage, manage side effects, and help them protect
                  their health<sup>3,6</sup>
                </li>
                <li>
                  <strong>Viral suppression. </strong>
                  Having a viral load test result of less than 200 copies/mL at
                  the most recent test. Essentially this means that the amount
                  of virus in a person’s blood is so low it cannot be measured
                  by a test. Viral suppression is the ultimate goal of HIV care
                  and treatment. Moreover, people who maintain viral suppression
                  have effectively no risk of passing HIV to others through sex
                  <sup>1</sup>
                </li>
              </ul>
              <img
                className="d-none d-lg-block mb-4 mt-4 pt-3 w-100"
                src={CareContinuumIcons}
                alt="Chart showing the steps of the HIV care continuum"
              />
              <img
                className="d-block d-lg-none mb-4 mt-2 w-100"
                src={CareContinuumIconsMobile}
                alt="Chart showing the steps of the HIV care continuum"
              />
              <p>
                It should be noted that viral suppression is not a one-time HIV
                treatment goal. In order to achieve and maintain viral
                suppression and experience the health and prevention benefits it
                provides, people living with HIV must stay in constant care and
                continue treatment.<sup>7</sup>
              </p>
              <h2 className="title pt-4">
                Two Ways to Track <br className="d-block d-lg-none" />
                Data
              </h2>
              <p>
                Tracking data along the HIV care continuum is an important tool
                in efforts to help end the HIV epidemic because it allows public
                health agencies—on the national, state, and local levels—to
                identify gaps in care and determine the types of interventions
                that might help increase the number of people who achieve and
                maintain viral suppression.<sup>4</sup>
              </p>
              <p>
                The CDC calculates the data in two ways.{" "}
                <strong>Diagnosis-based data</strong> are limited to people who
                have been diagnosed with HIV. These kinds of data are useful for
                tracking HIV treatment and care goals, analyzing disparities
                between groups of people living with HIV, and looking at
                different local responses to HIV. Linkage to care can only be
                calculated among people who have been diagnosed with HIV.
                <sup>1</sup>
              </p>
              <p>
                <strong>Prevalence-based data</strong> consider all people who
                are living with HIV, including those who have been diagnosed
                with HIV and the estimated number of people who have HIV but may
                not know it.<sup>1</sup> (In 2021, it was estimated that 1 in 8
                people living with HIV in the US did not know it.
                <sup style={{ left: "-1px" }}>8</sup>) These HIV-based data are
                useful for evaluating testing efforts, understanding the role
                new diagnoses play in the ultimate goal of achieving widespread
                viral suppression, and comparing data from the US with those of
                other countries.<sup>1</sup>
              </p>
              <p>
                Advocates use prevalence-based data in order to understand the
                full scope of the HIV epidemic, but progress along the HIV care
                continuum can be represented by both sets of data. While the
                number of people receiving HIV care or achieving viral
                suppression does not change between the two data sets, the
                percentage does, because the denominator changes. This is
                because prevalence-based data will always include more people;
                therefore, percentages will be lower.<sup>1</sup>
              </p>
              <div className="chart-bg">
                <Container>
                  <Row>
                    <Col xs={12} lg={6} className="px-0">
                      <div className="cont-chart two-ways-to-track first">
                        <h3>
                          HIV CARE CONTINUUM
                          <br />
                          PREVALENCE-BASED DATA <br />
                          IN THE US, 2021
                        </h3>
                        <div className="chart-area">
                          <div className="cont-img">
                            <img
                              src={"/hiv-care-continuum-data-prevalence-mobile.svg"}
                              alt="Bar Graph Showing HIV Care Continuum Data, 2019"
                              className="w-100 d-block d-md-none"
                            />
                            <img
                              src={"/hiv-care-continuum-data-prevalence.svg"}
                              alt="Bar Graph Showing HIV Care Continuum Data, 2019"
                              height={500}
                              className="w-100 d-none d-md-block"
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} lg={6} className="px-0">
                      <div className="cont-chart two-ways-to-track second">
                        <h3>
                          HIV CARE CONTINUUM
                          <br />
                          DIAGNOSIS-BASED DATA <br />
                          IN THE US, 2021
                        </h3>
                        <div className="chart-area">
                          <div className="cont-img">
                            <img
                              src={"/hiv-care-continuum-data-mobile.svg"}
                              alt="Bar Graph Showing HIV Care Continuum Data, 2019"
                              className="w-85 mx-auto d-block d-md-none"
                            />
                            <img
                              src={"/hiv-care-continuum-data.svg"}
                              alt="Bar Graph Showing HIV Care Continuum Data, 2019"
                              height={502}
                              className="w-100 d-none d-md-block"
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} className="mt-3">
                      <div className="footnote">
                        Source: CDC. Monitoring selected national HIV prevention
                        and care objectives by using HIV surveillance
                        data—United States and 6 dependent areas, 2021.&nbsp;
                        <a
                          target="_blank" /* data-analytics-event-category="External Links" */
                          href="https://www.cdc.gov/hiv/pdf/library/reports/surveillance/cdc-hiv-surveillance-report-vol-26-no-2.pdf"
                        >
                          <em>HIV Surveillance Supplemental Report</em>
                        </a>
                        . 2023;28(4).
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Container>
                <Row>
                  <Col xs={12} className="px-0">
                    <h2 className="title">HIV Prevention Continuum</h2>
                    <p>
                      The traditional HIV care continuum starts with a new HIV
                      diagnosis and explains the steps that individuals should
                      take from there to achieve viral suppression. While these
                      are important markers for people living with HIV, many
                      experts believe that, on both an individual and population
                      level, there needs to be a separate HIV care continuum
                      that addresses HIV prevention.<sup>9</sup>
                    </p>
                    <p>
                      Preventing new HIV infections is the cornerstone of the
                      national plans to help end the HIV epidemic.
                      <sup>10,11</sup> Traditional HIV prevention programs
                      include <strong>behavioral interventions</strong>, such as
                      efforts designed to increase condom use or stop needle
                      sharing, and <strong>structural interventions</strong>{" "}
                      that aim to remove barriers to these positive behaviors by
                      changing policies, such as making condoms available,
                      offering syringe services programs (SSPs), or providing
                      HIV education.<sup>9,12-15</sup> These programs remain
                      important, but the understanding of Treatment as
                      Prevention (TasP), which is considered{" "}
                      <strong>biomedical intervention</strong>, has radically
                      changed the HIV prevention landscape.<sup>16,17</sup>
                    </p>
                    <p>
                      Pre-exposure prophylaxis (PrEP)—another biomedical
                      intervention—is a prevention strategy for people who are
                      at risk for HIV. It involves using a prescription medicine
                      to reduce the risk of new HIV infection.<sup>16</sup>{" "}
                      Research shows that PrEP medicine is effective if used
                      correctly. The CDC recommends PrEP medicine for anyone who
                      is at high risk of getting HIV.<sup>18</sup>
                    </p>
                    <p>
                      Moreover, we now understand that individuals who use HIV
                      treatment as prescribed and achieve and maintain an
                      undetectable viral load, meaning the amount of virus in
                      the blood is so low it cannot be measured by a test,
                      effectively cannot transmit the virus to another person
                      through sex. This is sometimes referred to as U=U (or
                      Undetectable=Untransmittable)—a memorable campaign that
                      has become an important part of HIV prevention efforts.
                      <sup>19</sup>
                    </p>
                    <p>
                      Since the existing HIV care continuum does not account for
                      people at risk for HIV who might benefit from PrEP
                      medicine, experts have proposed a separate HIV prevention
                      continuum. The two continuums share the goal of preventing
                      new HIV infections.<sup>9</sup>
                    </p>
                    <h2 className="title pt-4">
                      HIV Status Neutral Prevention and Treatment Cycle
                    </h2>
                    <p>
                      There is also a growing movement among those working in
                      HIV for a new kind of continuum that can be applied to
                      everyone regardless of their HIV status. The New York City
                      Department of Health and Mental Hygiene (NYC DOHMH) has
                      been a leader in proposing this type of paradigm, which it
                      refers to as the HIV Status Neutral Prevention and
                      Treatment Cycle. This model suggests a sequence that
                      starts with HIV testing. If an individual is diagnosed
                      with HIV, they move into the classic HIV care continuum
                      and start treatment as soon as possible with the goal of
                      achieving viral suppression.<sup>20,21</sup>
                    </p>
                    <p>
                      If the individual tests negative, they move into an HIV
                      prevention continuum, which consists of understanding
                      their risk of HIV exposure, learning about PrEP medicine,
                      and discussing with a healthcare provider whether it is an
                      appropriate medication for them. Following those
                      discussions, some people will start using PrEP medicine,
                      while others may be guided toward other prevention
                      methods, such as condom use and more frequent HIV testing.
                      <sup>20-22</sup>
                    </p>
                    <p>
                      The goal of an HIV status-neutral cycle, however, is to be
                      able to apply the model to the population more broadly so
                      that everyone can take next steps to protect themselves
                      and their partners. This model recognizes that, to reach
                      the ultimate goal of ending HIV transmission, everyone
                      needs to remain engaged in prevention and care efforts.
                      The hope is that an HIV status-neutral cycle can also help
                      reduce stigma around HIV and HIV testing because it
                      presents testing as something everyone should be doing to
                      protect their health—not limited to those deemed “at risk”
                      of getting HIV.<sup>21</sup>
                    </p>
                    <p>
                      Last, another important characteristic of the HIV
                      status-neutral cycle is that it is viewed as continuous
                      without a set beginning or end. This nonlinear
                      representation more closely aligns with the experiences of
                      people who are living with HIV as well as those who are at
                      risk of getting it. Both groups benefit from an ongoing
                      relationship with a healthcare provider whether they need
                      HIV care and treatment or PrEP medicine and regular HIV
                      testing. It also recognizes that there is no real endpoint
                      to HIV care or prevention as both are continuous
                      throughout people’s lives.<sup>21,23</sup>
                    </p>
                    <img
                      className="d-none d-md-block mt-4 pt-4 w-100"
                      src={
                        "/infographic-hiv-status-neutral-prevention-treatment-cycle.svg"
                      }
                      alt="Infographic showing HIV Status Neutral Prevention and Treatment Cycles"
                    />
                    <img
                      className="d-block d-md-none mt-4 pt-4 w-100"
                      src={
                        "/infographic-hiv-status-neutral-prevention-treatment-cycle-mobile.svg"
                      }
                      alt="Infographic showing HIV Status Neutral Prevention and Treatment Cycles"
                    />
                    <div className="w-100">
                      <p className="mt-4 pt-2">
                        Source: Model adapted from Myers JE, et al.{" "}
                        <i>Open Forum Infect Dis</i>. 2018;5(6).&nbsp;
                        <br className="d-block d-lg-none" />
                        <a
                          target="_blank"
                          href="https://doi.org/10.1093/ofid/ofy097" /* data-analytics-event-category="External Links"
                  data-analytics-event-label="Click on https://doi.org/10.1093/ofid/ofy097" */
                          className="break-all-words"
                        >
                          https://doi.org/10.1093/ofid/ofy097
                        </a>
                      </p>
                      <p class="footnote">*Viral suppression is defined as having less than 200 copies of HIV per milliliter of blood.<sup>7</sup></p>
                    </div>
                  </Col>
                  <BackToTop />
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <References page={this.props.page} />
      </div>
    );
  }
}

export default Content;
